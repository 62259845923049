import React from 'react'
import PropTypes from 'prop-types'
import { trigger } from '../../packs/components/events'

const QuickView = ({ id, product_url, buttonStyles, advertise }) => {
  const getProductData = (e) => {
    e.preventDefault()
    $.ajax({
      dataType: 'json',
      error: function () {
        $.gritter.add({
          class_name: 'alert',
          fade: true,
          text: 'Failed to load Quick View modal',
          time: '10000',
          title: 'Notification',
        })
      },
      success: function (result) {
        trigger('closeAddToCartSuccessModal', {})
        trigger('openQuickViewModal', {
          advertise: advertise,
          paymentData: result,
          product_url: product_url,
        })
      },
      type: 'GET',
      url: `/${SPREE_LOCALE}/api/v2/storefront/products/${id}`,
    })
  }

  return (
    <button
      className="collectionCard_quickView buttonComponent--secondary"
      onClick={(e) => getProductData(e)}
      style={buttonStyles}
    >
      {Spree.translation('spree.tcred.quick_view.title')}
    </button>
  )
}

export default QuickView

QuickView.propTypes = {
  advertise: PropTypes.bool,
  buttonStyles: PropTypes.object,
  id: PropTypes.number,
  product_url: PropTypes.string,
}
