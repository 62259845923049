import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

// ImageWithText: A sub-component that renders an image along with some text content.
function ImageWithText({ section }) {
  const buttonStyles = {
    '--button-background': section.button_background,
    '--button-background-hover': section.button_background_hover,
    '--button-color': section.button_color,
    '--button-color-hover': section.button_color_hover,
  }

  return (
    <div className="textWithImage_row row">
      <div className="textWithImage_media col-lg-6">
        <img
          alt={section.image_alt}
          className="textWithImage_img"
          src={section.image_url}
        />
      </div>
      <div className="textWithImage_text col-lg-6">
        <h2 className="textWithImage_title">
          <strong>
            {section.title}
          </strong>
        </h2>
        <h3 className="textWithImage_title">{section.subtitle}</h3>
        <p className="textWithImage_body">{section.description}</p>
        {section.button_label.trim() !== '' && (
          <a
            aria-label="Learn more"
            className={`landingLinkBtn ${section.button_arrow}`}
            href={section.button_url}
            rel={section.link_open_in_new_tab ? 'noreferrer noopener' : ''}
            style={buttonStyles}
            target={section.link_open_in_new_tab ? '_blank' : '_self'}
          >
            {section.button_label}
          </a>
        )}
      </div>
    </div>)
}

ImageWithText.propTypes = {
  section: PropTypes.shape({
    button_arrow: PropTypes.string.isRequired,
    button_background: PropTypes.string.isRequired,
    button_background_hover: PropTypes.string.isRequired,
    button_color: PropTypes.string.isRequired,
    button_color_hover: PropTypes.string.isRequired,
    button_label: PropTypes.string.isRequired,
    button_url: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    image_alt: PropTypes.string.isRequired,
    image_url: PropTypes.string.isRequired,
    link_open_in_new_tab: PropTypes.bool.isRequired,
    subtitle: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
}

// ContentImageSection: A component that displays an image along with some text content.
export default function ContentImageSection({ section }) {
  const { is_gray, is_gradient, layout } = section.config

  const sectionClasses = classNames({
    imageRight: layout === 'right',
    'section--grey': is_gray,
    textWithImage: true,
    textWithImageGradient: is_gradient,
  })

  return (
    <section
      className={sectionClasses}
      key={section.node_id}
    >
      <div className="container">
        <div className="textWithImage_container container contentWrapper_large">
          <ImageWithText section={section} />
        </div>
      </div>
    </section>
  )
}

ContentImageSection.propTypes = {
  section: PropTypes.shape({
    button_arrow: PropTypes.string.isRequired,
    button_background: PropTypes.string.isRequired,
    button_background_hover: PropTypes.string.isRequired,
    button_color: PropTypes.string.isRequired,
    button_color_hover: PropTypes.string.isRequired,
    button_label: PropTypes.string.isRequired,
    button_url: PropTypes.string.isRequired,
    config: PropTypes.shape({
      is_gradient: PropTypes.bool.isRequired,
      is_gray: PropTypes.bool.isRequired,
      layout: PropTypes.oneOf(['left', 'right']).isRequired,
    }).isRequired,
    description: PropTypes.string.isRequired,
    image_alt: PropTypes.string.isRequired,
    image_url: PropTypes.string.isRequired,
    link_open_in_new_tab: PropTypes.bool.isRequired,
    node_id: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
}
