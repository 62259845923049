import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Loader from '../Loader'
import QuickView from '../QuickView'

const RecommendationsSection = ({ paymentData }) => {
  const [recommendations, setRecommendation] = useState(null)
  const [error, setError] = useState(null)

  const recommendationsRequest = () => $.ajax({
    error: function(res) {
      setError(res.responseJSON.error)
    },
    success: function(data) {
      setRecommendation(data)
    },
    type: 'get',
    url: Spree.routes.product_recommendations(paymentData.product_id),
  })

  useEffect(() => {
    recommendationsRequest()
  }, [])

  return (
    <div className="product-suggestions-content-wrapper" style={error ? {minHeight: '10rem'} : {}}>
      <h3 className="text-center">{Spree.translation('spree.tcred.confirmation_popup.recommendations')}</h3>
      {recommendations ?
        (
          <div className="recommendations-section">
            {recommendations.map((item) => {
              return (
                <div className="collectionCard_item" key={item.id}>
                  <div className="collectionCard">
                    <div className="collectionCard_wrapper">
                      <a className="collectionCard_imgLink" href={Spree.routes.product(item.id)} tabIndex="-1">
                        <div className="collectionCard_topLabel">
                          {item.top_label && item.top_label?.name}
                        </div>
                        {item.advertise &&
                          <span className="collectionCard_advertise">
                            {Spree.translation('spree.tcred.on_sale')}
                          </span>
                        }
                        <div className="collectionCard_media responsiveImage">
                          <img alt={item.name} className="collectionCard_img responsiveImage_img" src={item.image} />
                          <QuickView id={item.id} product_url={Spree.routes.product(item.id)} />
                        </div>
                        <div className="collectionCard_content">
                          <div className="collectionCard_textWrapper">
                            <h4 className="collectionCard_title">{item.name}</h4>
                            <div className={`collectionCard_label ${item.on_sale ? 'onSale' : ''}`}>
                              <span className="me-1">{Spree.translation('spree.from')}</span>
                              <span>{item.price}</span>
                              {item.on_sale &&
                                <span className="price-label price-old ms-2">{item.discount_price}</span>
                              }
                            </div>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        )
        : error ? <div className="error-center">{error}</div> : <Loader isLarge />
      }
    </div>
  )
}

export default RecommendationsSection

RecommendationsSection.propTypes = {
  paymentData: PropTypes.object,
}
