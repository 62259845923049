import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

const Search = ({ getProducts, isLoading, setKeyword, keyword }) => {
  const url = new URL(window.location)
  const [value, setValue] = useState(keyword)

  useEffect(() => {
    setValue(keyword)
  }, [keyword])

  const handleChange = (event) => {
    setValue(event.target.value)
  }

  const deleteQueryKeywords = () => {
    url.searchParams.delete('keywords')
    window.history.pushState({}, '', url)
  }

  return (
    <div className="collectionFilter_instant-filter col-lg-6">
      <input
        className="collectionFilter_instant-search"
        disabled={isLoading}
        onChange={handleChange}
        onKeyDown={(event) => {
          if (event.key === 'Enter') {
            setKeyword(value)
            if (value === '') deleteQueryKeywords()
          }
        }}
        placeholder={Spree.translation('spree.tcred.homepage.zwb.header.placeholder')}
        value={value}
      />
      <button
        className="collectionFilter_instant-button"
        disabled={isLoading}
        onClick={() => {
          setKeyword(value)
          getProducts(null, null, value)
          if (value === '') deleteQueryKeywords()
        }}
      >
        <span>
          <svg focusable="false" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"></path>
          </svg>
        </span>
      </button>
    </div>
  )
}

export default Search

Search.propTypes = {
  getProducts: PropTypes.func,
  isLoading: PropTypes.bool,
  keyword: PropTypes.string,
  setKeyword: PropTypes.func,
}
