import React from 'react'
import PropTypes from 'prop-types'

const WhatYouCanRecycle = ({ data }) => {

  return (
    <div className="accordion mt-5" id="quick_view_accordion">
      <div className="accordion_item">
        <h5 className="accordion_header">
          <button aria-controls="collapse1"
            aria-expanded="false"
            className="accordion_button"
            data-bs-target="#collapse1"
            data-bs-toggle="collapse"
            type="button"
          >
            {Spree.translation('spree.tcred.product_page.what_you_can_recycle.title')}
          </button>
        </h5>
        <div aria-labelledby="heading1"
          className="accordion_collapse collapse"
          data-bs-parent="#quick_view_accordion"
          id="collapse1"
        >
          <div className="accordion_body">
            <ul className="quickViewListChecked_list mb-4">
              {data.can_recycle_taxons.map(item => (
                <li className="quickViewListChecked_item smallText" key={item}>{item}</li>
              ))}
            </ul>
            <div>{Spree.translation('spree.tcred.product_page.what_you_can_recycle.do_not_send')}</div>
            <ul className="quickViewListTimes_list mt-4 mb-0">
              {data.cannot_recycle_taxons.map(item => (
                <li className="quickViewListTimes_item smallText" key={item}>{item}</li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

export default WhatYouCanRecycle

WhatYouCanRecycle.propTypes = {
  data: PropTypes.object,
}
