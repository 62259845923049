import React, { useCallback, useEffect, useState } from 'react'
import { off, on } from '../../packs/components/events'
import ShopModal from '../ShopModal'
import InfoActionSection from './InfoActionSection'
import ProductSection from './ProductSection'
import RecommendationsSection from './RecommendationsSection'

const AddToCartSuccessModal = () => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [data, setData] = useState({})

  const openModal = useCallback((e) => {
    setIsModalOpen(true)
    setData(e.detail)
  },[])

  const closeModal = () => {
    setIsModalOpen(false)
  }

  useEffect(() => {
    on('openAddToCartSuccessModal', openModal)
    on('closeAddToCartSuccessModal', closeModal)

    return () => {
      off('closeAddToCartSuccessModal', closeModal)
    }
  }, [openModal])

  const modalBody = () => {
    if (Object.keys(data).length !== 0) {
      const {addedCartItem, paymentData} = data

      return (
        <div className="add-to-cart-modal-body">
          <div className="product-content-wrapper">
            <ProductSection data={data} />
            <span className="separator" />
            <InfoActionSection
              addedCartItem={addedCartItem}
              handleClose={closeModal}
              paymentData={paymentData}
            />
          </div>
          <RecommendationsSection paymentData={paymentData} />
        </div>
      )
    }
  }

  return (
    <ShopModal
      handleClose={closeModal}
      isModalOpen={isModalOpen}
      modalBody={modalBody()}
      modalTitle={`${data.quantity} ${Spree.translation('spree.tcred.confirmation_popup.items_added')}`}
    />
  )
}

export default AddToCartSuccessModal
