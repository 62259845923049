import React from 'react'
import { TcCartOverlay } from '@tc-packages/tc-common'
import PropTypes from 'prop-types'

export default class TCCartOverlayWrapper extends React.Component {
  render() {
    const { config } = this.props

    return (
      <TcCartOverlay config={config} />
    )
  }
}

TCCartOverlayWrapper.propTypes = {
  config: PropTypes.object.isRequired,
}
