import React, { useEffect, useState, useRef, useCallback, useMemo } from 'react'
import ProductImageSlider from './ProductImageSlider'
import TCSanitizeHTML from '../TCSanitizeHTML'
import WhatYouCanRecycle from './WhatYouCanRecycle'
import TCPaymentMethodSelector from '../TCPaymentMethodSelector'
import ShopModal from '../ShopModal'
import { off, on } from '../../packs/components/events'

const Modal = () => {
  const paymentSelector = useRef(null)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [data, setData] = useState(null)
  const [descriptionHeight, setDescriptionHeight] = useState(0)
  const [triggerRender, setTriggerRender] = useState(false)

  // Toggle triggerRender to force a re-render after the modal is opened.
  useEffect(() => {
    if (isModalOpen) {
      setTriggerRender(prev => !prev)
    }
  }, [isModalOpen])

  const openModal = useCallback((e) => {
    setIsModalOpen(true)
    setData(e.detail)

    if (paymentSelector.current) {
      setDescriptionHeight(paymentSelector.current.offsetHeight)
    } else {
      setDescriptionHeight(0)
    }
  }, [])

  const closeModal = () => {
    setIsModalOpen(false)
    setData(null)
  }

  useEffect(() => {
    on('openQuickViewModal', openModal)

    return () => {
      off('openQuickViewModal', openModal)
    }
  }, [openModal])

  useEffect(() => {
    if (isModalOpen && paymentSelector.current) {
      setDescriptionHeight(paymentSelector.current.offsetHeight)
    }
  }, [isModalOpen, triggerRender])

  const modalBody = useMemo(() => {
    if (data) {
      const { paymentData, product_url } = data
      return (
        <div className="row">
          <ProductImageSlider
            images={paymentData.images}
            isOnSale={paymentData.advertise}
            t={Spree.translation('spree.tcred.on_sale')}
          />
          <div className="productRightSide col-lg-6 pe-5">
            <div ref={paymentSelector}>
              <TCPaymentMethodSelector
                addToCartCustomClass="quickViewAddToCart"
                avg_rating={paymentData.avg_rating}
                closeQuickViewModal={closeModal}
                data={paymentData}
                out_of_stock={!paymentData.in_stock}
                product_url={product_url}
                reviews_count={paymentData.reviews_count}
                self_served={false}
              />
            </div>
            <div
              className="productRightSide_descrWrapp"
              style={{ maxHeight: `calc(100vh - ${descriptionHeight + 130}px)` }}
            >
              <div className="productRightSide_description">
                {paymentData.advertise && (
                  <div className="productRightSide_advertisedPromotion">
                    <p className="mb-0">
                      <span className="productRightSide_advertisedPromotionLabel">
                        <strong>{Spree.translation('spree.tcred.limited_time')}&nbsp;</strong>
                      </span>
                      <TCSanitizeHTML className={'d-inline'} html={paymentData.advertised_promotion_description} />
                    </p>
                  </div>
                )}
                <h5>
                  <strong>{paymentData.description_title}</strong>
                </h5>
                <TCSanitizeHTML html={paymentData.description} />
              </div>
              {paymentData.can_recycle_taxons?.length > 0 && paymentData.cannot_recycle_taxons?.length > 0 && (
                <WhatYouCanRecycle data={paymentData} />
              )}
            </div>
            <div className="text-center mt-5">
              <a className="readMoreLink" href={`${product_url}`}>
                {Spree.translation('spree.tcred.quick_view.see_full_details')}
              </a>
            </div>
          </div>
        </div>
      )
    }
    return null
  }, [data, descriptionHeight])

  return (
    <ShopModal
      classes="wide-modal quickViewModal"
      handleClose={closeModal}
      isModalOpen={isModalOpen}
      modalBody={modalBody}
    />
  )
}

export default Modal
