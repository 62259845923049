import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

// ListImageSection: A component that displays a list of items with icons and a main image.
export default function ListImageSection({ section }) {
  const {
    button_color,
    button_background,
    button_color_hover,
    button_background_hover,
    link_open_in_new_tab,
    button_arrow,
  } = section
  const { is_gray } = section.config

  const buttonStyles = {
    '--button-background': button_background,
    '--button-background-hover': button_background_hover,
    '--button-color': button_color,
    '--button-color-hover': button_color_hover,
  }

  const sectionClasses = classNames({
    'section--grey': is_gray,
  })

  return (
    <section className={sectionClasses} key={section.node_id}>
      <div className="sectionTitle--centered">
        <div className="sectionTitle--centered_container container">
          <div className="sectionTitle--centered_contentWrapper contentWrapper_small">
            <h3 className="sectionTitle--centered_title">{section.title}</h3>
          </div>
        </div>
      </div>
      <div className="iconList">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <img
                alt={section.image_alt}
                className="iconList_mainImg"
                src={section.image_url}
              />
            </div>
            <div className="col-md-6">
              <ul className="iconList_list">
                {section.list_items.map(({ alt, content, icon, index }) => (
                  <li className="iconList_item" key={`iconList_item_${index}`}>
                    <img
                      alt={alt}
                      className="iconList_img"
                      src={icon}
                    />
                    <span className="iconList_txt">{content}</span>
                  </li>
                ))}
              </ul>
              {section.button_label.trim() !== '' && (
                <a
                  aria-label="Learn more"
                  className={`landingLinkBtn ${button_arrow}`}
                  href={section.button_url}
                  rel={link_open_in_new_tab ? 'noreferrer noopener' : ''}
                  style={buttonStyles}
                  target={link_open_in_new_tab ? '_blank' : '_self'}
                >
                  {section.button_label}
                </a>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

ListImageSection.propTypes = {
  section: PropTypes.shape({
    button_arrow: PropTypes.string.isRequired,
    button_background: PropTypes.string.isRequired,
    button_background_hover: PropTypes.string.isRequired,
    button_color: PropTypes.string.isRequired,
    button_color_hover: PropTypes.string.isRequired,
    button_label: PropTypes.string.isRequired,
    button_url: PropTypes.string.isRequired,
    config: PropTypes.shape({
      is_gray: PropTypes.bool.isRequired,
    }).isRequired,
    image_alt: PropTypes.string.isRequired,
    image_url: PropTypes.string.isRequired,
    link_open_in_new_tab: PropTypes.bool.isRequired,
    list_items: PropTypes.arrayOf(
      PropTypes.shape({
        alt: PropTypes.string.isRequired,
        content: PropTypes.string.isRequired,
        icon: PropTypes.string.isRequired,
        index: PropTypes.number.isRequired,
      })
    ).isRequired,
    node_id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
}
