import $ from 'jquery'
import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

const ProductImageSlider = ({ images, isOnSale, t }) => {
  useEffect(() => {
    $('.product-image_slider-carousel').not('.slick-initialized').slick({
      arrows: true,
      asNavFor: '.product-image_slider-thumbs',
      fade: true,
      responsive: [
        {
          breakpoint: 768,
          settings: 'unslick',
        },
      ],
      slidesToScroll: 1,
      slidesToShow: 1,
    })
    $('.product-image_slider-thumbs').not('.slick-initialized').slick({
      arrows: false,
      asNavFor: '.product-image_slider-carousel',
      focusOnSelect: true,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            dots: true,
            slidesToShow: 1,
            variableWidth: true,
            vertical: false,
          },
        },
      ],
      slidesToScroll: 1,
      slidesToShow: 5,
      vertical: true,
    })
  }, [])

  return (
    <div className="product-images-wrapper col-lg-6" data-hook="product_left_part_wrap">
      <div className="product-image_slider slick" id="slick-carousel">
        <div className="product-image_slider-thumbs">
          {images.map((image, i) => (
            <li className={`thumbnail tmb-${i}`} key={image}>
              <img alt={`tmb-${i}`} src={`${image}`} />
            </li>
          ))}
        </div>
        <div
          className={`product-image_slider-carousel ${isOnSale ? 'on-sale' : ''}`}
        >
          {images.map((image, i) => (
            <div className={`tmb-${i}`} key={image}>
              {isOnSale && (
                <div className="product_advertise"><span>{t}</span></div>
              )}
              <img alt={`thumbnail-${i}`} src={`${image}`} />
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default ProductImageSlider

ProductImageSlider.propTypes = {
  images: PropTypes.array,
  isOnSale: PropTypes.bool,
  t: PropTypes.string,
}
