import React from 'react'
import PropTypes from 'prop-types'

const Loader = ({ isLarge }) => {

  const largeSpiner = {
    height: '5rem',
    width: '5rem',
  }

  return (
    <div className="loader">
      {isLarge ?
        <div className="loader_spinner" style={largeSpiner} />
        :
        <div className="loader_spinner" />
      }
    </div>
  )
}

export default Loader

Loader.propTypes = {
  isLarge: PropTypes.bool,
}
