import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Loader from '../Loader'

const InfoActionSection = ({ addedCartItem, handleClose, paymentData }) => {
  const [isDisabled, setIsDisabled] = useState(false)

  return (
    <>
      {addedCartItem?.attributes ?
        (
          <div className="info-actions-section">
            <div className="item-info-wrapper">
              <div className="item-info">
                <p>{Spree.translation('spree.tcred.confirmation_popup.subtotal', { quantity: addedCartItem.attributes.item_count })}:</p>
                <p>{addedCartItem.attributes.display_item_total}</p>
              </div>

              {addedCartItem.attributes.total_discount &&
                <div className="item-info grey-text">
                  <p>{Spree.translation('spree.total_discount_applied')}:</p>
                  <p>{addedCartItem.attributes.total_discount}</p>
                </div>
              }

              {false ?
                <>
                  {/* This part will be done in some time, now it will be hided from the client */}
                  <div className="item-info">
                    <p>{Spree.translation('spree.cart_total')}:</p>
                    <p className="green-amount">{addedCartItem.attributes.display_total}</p>
                  </div>
                </>
                :
                null
              }
            </div>
            <div className="actions-wrapper">
              <button
                className="buttonComponent--primary w-100 mt-3"
                disabled={isDisabled}
                onClick={() => {
                  window.location = `${Spree.routes.cart}?variant_id=${paymentData.variantId}`
                  setIsDisabled(true)
                }}
                type="button"
              >
                {Spree.translation('spree.tcred.confirmation_popup.view_cart')}
              </button>

              <button
                className="buttonComponent--secondary w-100 mt-3 ms-0"
                disabled={isDisabled}
                onClick={handleClose}
                type="button"
              >
                {Spree.translation('spree.tcred.confirmation_popup.continue_shopping')}
              </button>
            </div>
          </div>
        )
        :
        <Loader isLarge />
      }
    </>
  )
}

export default InfoActionSection

InfoActionSection.propTypes = {
  addedCartItem: PropTypes.object,
  handleClose: PropTypes.func,
  paymentData: PropTypes.object,
}
