import { Tab, Tabs } from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'
import { trigger } from '../packs/components/events'
import BasePaymentTab from './PaymentMethodSelector/BasePaymentTab'
import ItemPropertySelector from './PaymentMethodSelector/ItemPropertySelector'
import NumberInput from './PaymentMethodSelector/NumberInput'
import PriceLabel from './PaymentMethodSelector/PriceLabel'
import RadioSizeSelector from './PaymentMethodSelector/RadioSizeSelector'
import TabPanel from './PaymentMethodSelector/TabPanel'
import TCReview from './TCReview'
import TCStickyAddToCart from './TCStickyAddToCart'
import TCVolumePricesTable from './TCVolumePricesTable'

// Does nothing but break the bfcache in Firefox and Safari
window.addEventListener('pagehide', () => { })

export const ActionContext = React.createContext()
export default class TCPaymentMethodSelector extends React.Component {
  constructor(props) {
    super(props)
    const paymentData = props.data.payment_data ? props.data.payment_data : props.data

    if (paymentData.variants.length) {
      paymentData.price = paymentData.variants[0].price
      paymentData.variantId = paymentData.variants[0].value
      paymentData.variant = paymentData.variants[0]
      if (paymentData.product_name === undefined) {
        paymentData.product_name = paymentData.variants[0].product_name
      }
    }

    const recurring = props.data.subscribable
    const selectedSubscription = recurring && props.data.subscriptions[0].value

    this.state = {
      addedCartItem: {},
      brand_name: paymentData.brand_name,
      buttonDisabled: props.out_of_stock,
      isCheckboxChecked: false,
      limit: paymentData.limit,
      old_price: paymentData.old_price,
      on_sale: paymentData.on_sale,
      paymentData: paymentData,
      price: paymentData.price,
      product_img_url: props.product_img_url,
      quantity: 1,
      recurring: recurring,
      selectedSubscription,
      showCheckboxHint: false,
      subsidy: paymentData.subsidy,
      tabIndex: 0,
      title: paymentData.product_name,
      variant: paymentData.variant,
      variantId: paymentData.variantId,
      volume_prices: paymentData.variant.volume_prices,
    }
  }

  componentWillUnmount() {
    this.setState({ buttonDisabled: false })
  }

  basePaymentForm = (data, avg_rating, reviews_count) => <BasePaymentTab avg_rating={avg_rating} data={data} reviews_count={reviews_count} />

  a11yProps = index => {
    return {
      'aria-controls': `simple-tabpanel-${index}`,
      id: `simple-tab-${index}`,

    }
  }

  handleOpenModal = () => {
    trigger('openAddToCartSuccessModal', {
      addedCartItem: this.state.addedCartItem,
      paymentData: this.state.paymentData,
      quantity: this.state.quantity,
      variant: this.state.variant,
      volume_price: this.state.volumePrice,
    })

    if (this.props.closeQuickViewModal !== undefined) {
      this.props.closeQuickViewModal()
    }
  }

  handleChange = (event, newValue) => {
    this.setState({ tabIndex: newValue })

  }

  // Checkbox change handler
  handleCheckboxChange = (event) => {
    this.setState({
      isCheckboxChecked: event.target.checked,
      showCheckboxHint: false, // Hide hint when checkbox state changes
    })
  }

  gaAddToCart = (variant, quantity) => {
    window.dataLayer = window.dataLayer || []
    dataLayer.push({ ecommerce: null }) // Clear the previous ecommerce object.
    dataLayer.push({
      ecommerce: {
        items: [{
          currency: variant.currency,
          item_id: variant.sku,
          item_name: variant.product_name,
          price: parseFloat(variant.price.replace(/[^\d.-]/g, '')),
          quantity: quantity,
        }],
      },
      event: 'add_to_cart',
    })
    dataLayer.push({
      ecommerce: {
        items: [{
          currency: variant.currency,
          item_id: variant.sku,
          item_name: variant.product_name,
          price: parseFloat(variant.price.replace(/[^\d.-]/g, '')),
          quantity: quantity,
        }],
      },
      event: 'add_to_cart_new',
    })
  }

  addToCart = (fromStickyButton = false) => {
    // If the subscription (recurring) is active and checkbox is unchecked
    if (this.state.tabIndex === 1 && !this.state.isCheckboxChecked) {
      this.setState({ showCheckboxHint: true })

      // Scroll the checkbox into view when clicked from the stickyAddToCart button
      if (fromStickyButton) {
        const checkboxElement = document.getElementById('acceptPurchase')
        if (checkboxElement) {
          const checkboxPosition = checkboxElement.getBoundingClientRect().top + window.pageYOffset - 290
          window.scrollTo({behavior: 'smooth', top: checkboxPosition}) // Scroll to the offset position
        }
      }

      return
    }

    this.setState({ buttonDisabled: true })
    let options

    if (this.state.tabIndex) {
      const subscription = this.props.data.subscriptions.find((subs) => subs.value === this.state.selectedSubscription)

      options = {
        subscribe: true,
        [subscription.options.type]: subscription.options.id,

      }
    }

    Spree.ensureCart(() => {
      SpreeAPI.Storefront.addToCart(
        this.state.variantId,
        this.state.quantity,
        options,
        (response) => {
          this.gaAddToCart(this.state.variant, this.state.quantity)

          if (this.props.closeQuickViewModal && window.location.pathname.match(/\/checkout|\/cart/)) {
            window.location = `${Spree.routes.cart}`
          } else {
            const fetchCartCountEvent = new CustomEvent('FETCH_CART_COUNT', {})
            document.dispatchEvent(fetchCartCountEvent)

            const isMobile = window.innerWidth >= 991
            isMobile ? (
              this.setState({ addedCartItem: response }, () => {
                this.setState({ buttonDisabled: false }, this.handleOpenModal)
              })
            ) : (
              window.location = `${Spree.routes.cart}?variant_id=${this.state.variantId}`
            )
          }
        },
        (err) => {
          const quantitySection = document.querySelector('.quickViewModal #quantitySection') || document.getElementById('quantitySection')

          if (quantitySection) {
            quantitySection.insertAdjacentHTML('beforeend', `<div class="error w-100">${err}</div>`)
          }

          return err
        }
      )
    })
  }

  updateQuantityValue = (quantity) => {
    this.setState({ quantity: quantity })

    if (this.getVolumePrice(quantity, this.state.variant.volume_prices)) {
      this.state.price = this.getVolumePrice(quantity, this.state.variant.volume_prices)
    } else {
      this.state.price = this.state.variant.price
    }
  }

  getVolumePrice = (quantity, variantVolumePrices) => {
    for (let i = 0; i < variantVolumePrices.length; i++) {
      const range = variantVolumePrices[i].range
      const price = variantVolumePrices[i].price
      const isBetter = parseFloat(price.substring(1)) <= parseFloat(this.state.variant.price.substring(1))

      if (quantity >= parseInt(range[0], 10) && quantity <= parseInt(range[1], 10) && isBetter) {
        this.setState({
          volumePrice: price,
        })
        return price
      } else {
        this.setState({
          volumePrice: null,
        })
      }
    }

    return false
  }

  updateSizeValue = (e) => {
    const variant = this.state.paymentData.variants.length && this.state.paymentData.variants.filter(item => item.value === e)[0]
    let priceSelector = document.querySelectorAll('.productRightSide_priceNormal')[0],
      salePriceSelector = document.querySelectorAll('.productRightSide_priceSale')[0],
      onSaleSelector = document.querySelectorAll('.onSale'),
      subsidySelector = document.querySelectorAll('.productRightSide_subsidy')[0]

    this.setState({
      brand_name: variant.brand_name,
      limit: variant.limit,
      old_price: variant.old_price,
      on_sale: variant.on_sale,
      price: variant.price,
      subsidy: variant.subsidy,
      variant: variant,
      variantId: e,
    })

    priceSelector.innerHTML = variant.price

    if (variant.on_sale) {
      onSaleSelector.forEach(el => el.removeAttribute('style'))
      salePriceSelector.innerHTML = variant.old_price
    } else {
      onSaleSelector.forEach(el => el.style.display = 'none')
    }

    if (variant.on_sale && this.props.self_served) {
      subsidySelector.innerHTML = variant.subsidy
    }

    if (this.getVolumePrice(this.state.quantity, variant.volume_prices)) {
      let volumePriceValue = this.getVolumePrice(this.state.quantity, variant.volume_prices)
      this.setState({
        price: volumePriceValue,
        volumePrice: volumePriceValue,
      })
    } else {
      this.setState({
        price: variant.price,
        volumePrice: null,
      })
    }
  }

  updateSubscriptionValue = (subscriptionValue) => {
    this.setState({ selectedSubscription: subscriptionValue })

  }

  render() {
    const { subscriptions } = this.props.data
    const { avg_rating, reviews_count } = this.props
    const filteredItemList = this.state.paymentData.variants.filter(item => !!item.name)

    return (
      <div>
        <div className="d-none d-md-block">
          <TCReview
            avg_rating={avg_rating}
            product_url={this.props.product_url}
            reviews_count={reviews_count}
          />
          <h3 className="productRightSide_name">{this.state.title}</h3>
        </div>
        <div className="productRightSide_price">
          <div className="d-none d-md-block">
            <PriceLabel
              brand={this.state.brand_name}
              old_price={this.state.old_price}
              on_sale={this.state.on_sale}
              price={this.state.price}
              self_served={this.props.self_served}
              subsidy={this.state.subsidy}
            />
          </div>
          {this.state.recurring &&
            <div className="productRightSide_tabs" role="tablist">
              <Tabs
                aria-label="simple tabs"
                onChange={this.handleChange}
                value={this.state.tabIndex}
              >
                <Tab
                  label={Spree.translation('spree.payment_selector.labels.one_time_order')}
                  {...this.a11yProps(0)}
                />
                <Tab
                  label={Spree.translation('spree.payment_selector.labels.subscription')}
                  {...this.a11yProps(1)}
                />
              </Tabs>
            </div>}
        </div>
        <div className="bootstrapForm">
          <ActionContext.Provider value={this.actions}>
            {filteredItemList.length > 0 &&
              <div className="productSize_label">
                <span>{Spree.translation('spree.payment_selector.labels.size')}: <strong>{this.state.variant.name}</strong></span>
              </div>
            }
            <TabPanel index={0} value={this.state.tabIndex}>
              <div className="row">
                <RadioSizeSelector
                  itemList={this.state.paymentData.variants}
                  itemName="size"
                  sizeClass="col-md-6"
                  updateValue={this.updateSizeValue}
                  variant={this.state.variant.value}
                />
              </div>
            </TabPanel>
            {this.state.recurring &&
              <TabPanel index={1} value={this.state.tabIndex}>
                <div className="row">
                  <RadioSizeSelector
                    itemList={this.state.paymentData.variants}
                    itemName="size"
                    sizeClass="col-md-6"
                    updateValue={this.updateSizeValue}
                    variant={this.state.variant.value}
                  />
                  <ItemPropertySelector
                    itemList={subscriptions}
                    itemName="subscription"
                    sizeClass="col-md-6 mt-4"
                    updateValue={this.updateSubscriptionValue}
                    variant={this.state.selectedSubscription}
                  />
                </div>
                <div className="checkbox mt-4 pt-3 subscription-consent">
                  <input
                    checked={this.state.isCheckboxChecked}
                    id="acceptPurchase"
                    name="accept_purchase"
                    onChange={this.handleCheckboxChange}
                    type="checkbox"
                  />
                  <label className="control-label" htmlFor="acceptPurchase">
                    <div className="d-inline" dangerouslySetInnerHTML={{ __html: Spree.translation('spree.accept_purchase.label') }}></div>
                    <abbr className="d-inline float-none" title="You are missing a required field.">*</abbr>
                  </label>
                  {this.state.showCheckboxHint &&
                      <div className="help-inline">
                        {Spree.translation('spree.accept_purchase.must_be_accepted')}
                      </div>
                  }
                </div>
              </TabPanel>}
            <div className="productRightSide_quantity" id="quantitySection">
              {(!this.props.self_served || this.state.limit > 1) &&
                <NumberInput
                  itemLabel={Spree.translation('spree.payment_selector.labels.quantity')}
                  itemValue={this.state.quantity}
                  updateValue={this.updateQuantityValue}
                />
              }
              <div className="productRightSide_quantityAddToCart">
                <button
                  className={`${this.props.addToCartCustomClass || 'mainAddToCart'} buttonComponent--primary`}
                  disabled={this.state.buttonDisabled}
                  id="mainAddToCart"
                  onClick={this.addToCart}
                  type="button"
                >
                  {this.state.tabIndex === 1 ?
                    Spree.translation('spree.payment_selector.buttons.subscribe') :
                    Spree.translation('spree.payment_selector.buttons.add_to_cart')}
                </button>
              </div>
            </div>
          </ActionContext.Provider>
        </div>
        {this.props.show_sticky_add_to_cart &&
        <TCStickyAddToCart>
          <div className="container">
            <div className="d-flex flex-row align-items-center d-none d-lg-flex">
              <div className="d-flex">
                <img alt={this.state.old_price} className="productImage" src={this.state.product_img_url} />
              </div>
              <div className="d-flex align-items-center ms-4">
                <h3 className="productRightSide_name">{this.state.title}</h3>
              </div>
              <div className="d-flex flex-column justify-content-start h-100 ms-lg-3 ps-lg-3 ms-5 ps-5">
                <PriceLabel
                  old_price={this.state.old_price}
                  on_sale={this.state.on_sale}
                  price={this.state.price}
                />
                <div>
                  <ActionContext.Provider value={this.actions}>
                    <TabPanel index={0} value={this.state.tabIndex}>
                      <ItemPropertySelector
                        itemList={this.state.paymentData.variants}
                        itemName="size"
                        sizeClass="stickyBarSelect"
                        updateValue={this.updateSizeValue}
                        variant={this.state.variant.value}
                      />
                    </TabPanel>
                    {this.state.recurring &&
                      <TabPanel index={1} value={this.state.tabIndex}>
                        <ItemPropertySelector
                          itemList={this.state.paymentData.variants}
                          itemName="size"
                          sizeClass="stickyBarSelect mb-3"
                          updateValue={this.updateSizeValue}
                          variant={this.state.variant.value}
                        />
                        <ItemPropertySelector
                          itemList={subscriptions}
                          itemName="subscription"
                          sizeClass="stickyBarSelect"
                          updateValue={this.updateSubscriptionValue}
                          variant={this.state.selectedSubscription}
                        />
                      </TabPanel>
                    }
                  </ActionContext.Provider>
                </div>
              </div>

              <div className="align-items-center d-flex justify-content-end w-100">
                <NumberInput
                  itemLabel={Spree.translation('spree.payment_selector.labels.quantity')}
                  itemValue={this.state.quantity}
                  updateValue={this.updateQuantityValue}
                />
                <div className="productRightSide_quantityAddToCart">
                  <button
                    className="stickyAddToCart buttonComponent--primary"
                    disabled={this.state.buttonDisabled}
                    onClick={() => this.addToCart(true)}
                    type="button"
                  >
                    {this.state.tabIndex === 1 ?
                      Spree.translation('spree.payment_selector.buttons.subscribe') :
                      Spree.translation('spree.payment_selector.buttons.add_to_cart')}
                  </button>
                </div>
              </div>
            </div>
            <div className="stickyBarMobile d-flex d-lg-none flex-column align-items-center">
              <div className="d-flex align-items-center flex-column">
                <h3 className="productRightSide_name">{this.state.title}</h3>
                <PriceLabel
                  old_price={this.state.old_price}
                  on_sale={this.state.on_sale}
                  price={this.state.price}
                />
              </div>
              <div className="d-flex w-100 justify-content-center mt-1">
                <button
                  className="stickyAddToCart buttonComponent--primary"
                  disabled={this.state.buttonDisabled}
                  onClick={() => this.addToCart(true)}
                  type="button"
                >
                  {this.state.tabIndex === 1 ?
                    Spree.translation('spree.payment_selector.buttons.subscribe') :
                    Spree.translation('spree.payment_selector.buttons.add_to_cart')}
                </button>
              </div>
            </div>
          </div>
        </TCStickyAddToCart>
        }
        {/* QuickView */}
        {this.state.variant.volume_prices?.length > 0 && this.props.closeQuickViewModal &&
          <div className="my-5">
            <a className="readMoreLink" href={`${this.props.product_url}`}>{Spree.translation('spree.volume_pricing_available')}</a>
          </div>
        }
        {/* Details page */}
        {this.state.variant.volume_prices?.length > 0 && !this.props.closeQuickViewModal &&
          <TCVolumePricesTable ranges={this.state.variant.volume_prices}></TCVolumePricesTable>
        }
      </div>
    )
  }
}

TCPaymentMethodSelector.propTypes = {
  addToCartCustomClass: PropTypes.string,
  avg_rating: PropTypes.string,
  closeQuickViewModal: PropTypes.func,
  data: PropTypes.object,
  out_of_stock: PropTypes.bool,
  product_img_url: PropTypes.string,
  product_url: PropTypes.string,
  reviews_count: PropTypes.number,
  self_served: PropTypes.bool,
  show_sticky_add_to_cart: PropTypes.bool,
}
