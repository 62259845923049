import PropTypes from 'prop-types'
import React from 'react'

const ProductSection = ({ data }) => {
  const {variant, quantity, addedCartItem, volume_price} = data
  const lineItemPrice = addedCartItem?.attributes?.line_item_price
  const isBetter = (parseFloat(volume_price?.substring(1)) || parseFloat(lineItemPrice?.substring(1))) >= parseFloat(variant?.price?.substring(1))
  const discountedPrice = isBetter ? lineItemPrice : volume_price ? volume_price : lineItemPrice
  const isShouldBeVisible = (isBetter ? lineItemPrice : volume_price) !== variant.old_price

  return (
    <div className="product-section">
      <div className="image-wrapper">
        <img alt={variant.product_name} height={158} src={variant.image_path} width={152} />
      </div>
      <div className="product-info-wrapper">
        <h3 className="product-name">{variant.product_name}</h3>
        <div className="product-description">{variant.name}</div>
        <div className="product-description">{Spree.translation('spree.payment_selector.labels.quantity')}: {quantity}</div>
        <div className={`product-price-wrapper ${isShouldBeVisible ? 'onSale' : ''}`}>
          {isShouldBeVisible ? <div className="product-price">{discountedPrice}</div> : null}
          <div className={isShouldBeVisible ? 'volumePrice' : 'product-price'}>{variant.old_price}</div>
        </div>
      </div>
    </div>
  )
}

export default ProductSection

ProductSection.propTypes = {
  data: PropTypes.object,
}
